* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  font-family: 'Encode Sans Expanded', sans-serif
}

.payment-button {
  background: #01BF71;
  padding: 16px 0;
  border: none;
  border-radius: 4px;
  color: #F9F9F9;
  font-size: 20px;
  cursor: pointer;
  margin-top: 2rem;
}